import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import { format } from "date-fns";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Carousel } from "react-responsive-carousel";
import { Link } from "gatsby";
import contentfulClient from "../../utils/contentful-helper";
import Layout from "../../components/layout";
import PDFViewer from "../../components/pdf-viewer";
import { getURLParams } from "../../utils/common-helper";
import BackIcon from "../../assets/images/icons/arrow-back.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/pages/events.scss";

export default function PropertyDetails({ location }) {
  const id = getURLParams(location.search, "id");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  console.log("prop data", data);
  const happenedOn = format(
    new Date(_get(data, "fields.happenedOn", 1)),
    "do LLL, y"
  );
  const getData = () => {
    setLoading(true);
    return contentfulClient
      .getEntry(id)
      .then((d) => {
        setData({ ...d });
      })
      .catch(() => {
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Properties for Rental, Lease and Sale">
      <Link className="back-link" to={`/events`}>
        <h1 className="page-title">
          <img src={BackIcon} alt="back-button" />
          <span>Back to All Events</span>
        </h1>
      </Link>
      {loading ? (
        <div className="loading">
          <p>Loading, please wait!</p>
        </div>
      ) : (
        <>
          <div className="action-details">
            <div className="action-info">
              <h1>{_get(data, "fields.title", "")}</h1>
              <p className="action-timestamp">
                Happened on <span>{happenedOn}</span>
              </p>

              <div className="action-description">
                {documentToReactComponents(_get(data, "fields.description"))}
              </div>
            </div>
          </div>
          <h4>Gallery</h4>
          <Carousel
            showArrows={false}
            showIndicators={false}
            className="events-gallery"
          >
            {_get(data, "fields.gallery", []).map((image) => {
              return (
                <div>
                  <img
                    src={_get(image, "fields.file.url", "")}
                    alt={_get(image, "fields.title", "")}
                  />
                  <p className="legend">{_get(image, "fields.title", "")}</p>
                </div>
              );
            })}
          </Carousel>
          {_get(data, "fields.mom.fields.file.url") && (
            <>
              <h4>Minutes of Meeting</h4>
              <div className="pdf-viewer">
                <PDFViewer
                  id={_get(data, "sys.id", "events-viewer")}
                  fileData={{
                    url: _get(data, "fields.mom.fields.file.url", ""),
                    fileName: _get(data, "fields.mom.fields.file.fileName", ""),
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </Layout>
  );
}
